import { Box, useInterval } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useStateList } from 'react-use';

const FadeGallery = ({ children, duration = 5000 }) => {
  const motionedChildren = useMemo(
    () =>
      React.Children.map(children, (child) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            transform: 'translate3d(0,0,0)',
          }}
        >
          {child}
        </motion.div>
      )),
    [children]
  );
  const { state, next, currentIndex } = useStateList(motionedChildren);
  useInterval(next, duration);
  return (
    <Box position="relative" pointerEvents="none">
      <Box tabIndex="-1" pointerEvents="none" opacity={0} visibility="hidden">
        {children[currentIndex]}
      </Box>
      <Box
        pos="absolute"
        tabIndex="-1"
        pointerEvents="none"
        opacity={0}
        visibility="hidden"
        top="0"
      >
        {children[currentIndex + 1] || null}
      </Box>
      <AnimatePresence initial={false}>{state}</AnimatePresence>
    </Box>
  );
};

export default FadeGallery;
