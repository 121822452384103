import React from 'react'
import { Box, Divider, Flex } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import Link from 'components/Link'

// import relab from './relab.png'
// import wtd from './wtd.png'

const Footer = (props) => {
  return (
    <Flex position={'absolute'} left={0} right={0} bottom={'2.25em'} alignItems="center" justifyContent="center" {...props}>
      <Box as={Link} width={'4.75em'} href="https://relab.cc/">
        <StaticImage src="./relab.png" placeholder="tracedSVG" alt="RE:LAB" layout="fullWidth" />
      </Box>
      <Divider mx="1.125rem" borderLeftWidth={'2px'} height={'1.25rem'} borderColor="dividerColor" orientation='vertical' />
      <Box width={'4.75em'}>
        <Link href="https://whatthedata.cc/">
          <StaticImage src="./wtd.png" placeholder="tracedSVG"  alt="What the data" layout="fullWidth" />
        </Link>
      </Box>
    </Flex>
  )
}

export default Footer
