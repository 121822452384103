import React, { useEffect, useMemo } from 'react'
import { Text, Box, Button, Container } from '@chakra-ui/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import shuffle from 'lodash/shuffle'

import Link from 'components/Link'
import Footer from 'containers/Footer'
import { responsive } from 'contexts/responsive'
import useHeader from 'contexts/header/useHeader'

import BlockModule from 'components/BlockModule'

import heart from './heart.png'
import FadeGallery from 'components/FadeGallery'

const IndexPage = () => {
  const { setHideHeader, hideHeader } = useHeader()
  useEffect(() => {
    setHideHeader(true)
  }, [])
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      allFile(
        filter: {relativeDirectory: {eq: "covers"}}
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  const shuffleCity = useMemo(() => shuffle(data.allFile.nodes), [])
  if (!hideHeader) return null
  return (
    <BlockModule
      city={
        <Box
          pos="absolute"
          left={responsive('-50%', '0')}
          right={responsive('-50%', '0')}
          bottom={0}
        >
          <FadeGallery>
            {shuffleCity.map(({ name, childImageSharp }) => (
              <GatsbyImage image={childImageSharp.gatsbyImageData} alt="台灣各地的照片" key={name} style={{ width: '100%' }} />
            ))}
          </FadeGallery>

        </Box>
      }
      footer={<Footer />}
      percent={69}
      bgTop="-15%"
      bg={heart}
    >
      <Container>
        <Box pos="relative" pt="10%" pb="5.75rem">
          <Box width={'45vh'} minWidth="50%" maxW="min(80%,24rem)" mx="auto">
            <StaticImage src="./match.png" layout="fullWidth" />
          </Box>
          <Box
            textAlign={'center'}
            fontSize={responsive('1.125em', '1.25em')}
            pos="relative"
            color="textColor"
            mt="1.75rem"
            fontWeight={700}
          >
            <Text>
              選選看你在意的事
              <br />
              3步驟為你配對最適合的城市
            </Text>
            <Button
              as={Link}
              mt="4rem"
              to="/pair"
              px="1.875rem"
              fontSize={responsive('1.375em', '1.5em')}
            >
              開始配對
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </BlockModule>
  )
}

export default IndexPage
